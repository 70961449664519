@import "../_variables/typography.less";

@import "../../../../node_modules/@is24/cosma-design-tokens/build/less/styles.text.less";

.typography-heading-xlarge-bold {
	&:extend(.typography-heading-xlarge-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
}

.typography-heading-xlarge-regular {
	&:extend(.typography-heading-xlarge-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
}

.typography-heading-large-bold {
	&:extend(.typography-heading-large-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
}

.typography-heading-large-regular {
	&:extend(.typography-heading-large-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
}

.typography-heading-medium-bold {
	&:extend(.typography-heading-medium-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
}

.typography-heading-medium-regular {
	&:extend(.typography-heading-medium-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
}

.typography-heading-small-bold {
	&:extend(.typography-heading-small-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
}

.typography-heading-small-regular {
	&:extend(.typography-heading-small-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
}

.typography-heading-xsmall-bold {
	&:extend(.typography-heading-xsmall-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
}

.typography-heading-xsmall-regular {
	&:extend(.typography-heading-xsmall-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
	.heading-margin();
}

.typography-body-large-bold {
	&:extend(.typography-body-large-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
}

.typography-body-large-regular {
	&:extend(.typography-body-large-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
}

.typography-body-medium--default--bold {
	&:extend(.typography-body-medium--default--bold all);
	font-family: @fontFamilyStandard-theme-cosma;
}

.typography-body-medium--default--regular {
	&:extend(.typography-body-medium--default--regular all);
	font-family: @fontFamilyStandard-theme-cosma;
}

.typography-body-small-bold {
	&:extend(.typography-body-small-bold all);
	font-family: @fontFamilyStandard-theme-cosma;
}

.typography-body-small-regular {
	&:extend(.typography-body-small-regular all);
	font-family: @fontFamilyStandard-theme-cosma;
}

h1 {
	&:extend(.typography-heading-xlarge-bold all);
	.heading-margin();
}

h2 {
	&:extend(.typography-heading-large-bold all);
	.heading-margin();
}

h3 {
	&:extend(.typography-heading-medium-bold all);
	.heading-margin();
}

h4 {
	&:extend(.typography-heading-small-bold all);
	.heading-margin();
}

h5 {
	&:extend(.typography-heading-xsmall-bold all);
	.heading-margin();
}

.heading-margin {
	margin-bottom: 0.4em;
}
