@import "../../../less/_mixins/general.less";
@import "../../../less/_variables/media-queries.less";
@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/typography.less";

.progress-stepper {
	@icon-size: 20px;
	@icon-container: 24px;
	@min-connector-length: 80px;
	@connector-size: 1px;
	@column-gap: 8px;
	@row-gap: 4px;

	display: flex;
	flex-direction: column;

	.step-horizontal-view {
		grid-template-areas:
			"header header connector connector"
			". content content content";
		grid-template-columns: @icon-container auto @min-connector-length max-content;
		grid-template-rows: @icon-container auto;
		align-items: start;
		margin-right: @column-gap;
		margin-bottom: 0;

		&::after {
			width: 100%;
			height: @connector-size;
			overflow: hidden;
		}
	}

	.step {
		display: grid;
		grid-template-columns: @icon-container auto;
		column-gap: @column-gap;
		row-gap: @row-gap;
		grid-template-rows: max-content;
		grid-template-areas:
			"header header"
			"connector content";
		align-items: center;
		overflow: hidden;
		white-space: pre-line;

		&::after {
			grid-area: connector;
			place-self: center;
			display: block;
			background-color: @color-border-input-theme-cosma;
			width: @connector-size;
			height: 100%;
			content: "";
		}

		&-status {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			margin: 2px;
			font: @fontFamilyStandard-theme-cosma;
			font-size: @fontSizeXS;
			font-weight: 700;
			margin-right: @column-gap;

			&--current,
			&--next {
				height: @icon-size;
				width: @icon-size;
			}

			&--current {
				background-color: @black;
				color: @white;
			}
			&--next {
				color: @color-border-input-theme-cosma;
				border: @color-border-input-theme-cosma solid 2px;
			}
			&--done {
				color: @color-primary-hover-theme-cosma;
				margin-right: calc(@column-gap - 2px);
			}
		}

		&-name {
			margin: 0;
		}

		&-content {
			grid-area: content;
			align-self: start;
		}

		&-short-description {
			white-space: pre-line;
		}

		&-header {
			grid-area: header;
			align-self: center;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			width: max-content;

			&--interactive {
				cursor: pointer;

				&:focus:focus-visible,
				&:hover,
				&:focus:not(:focus-visible) {
					text-decoration: none;
				}

				&:focus:focus-visible,
				&:hover {
					.step-name {
						text-decoration: underline;
						color: @black;
					}
				}
			}
		}
		.step-name--done {
			color: var(--color-text-default);
			font-weight: normal;
		}
		.step-name--current {
			color: var(--color-text-default);
			font-weight: 600;
		}
		.step-name--next {
			color: var(--color-text-button-disabled);
			font-weight: normal;
		}
		.step-text--done {
			color: var(--color-text-default);
			font-weight: normal;
		}
		.step-text--current {
			color: var(--color-text-default);
			font-weight: 400;
		}
		.step-text--next {
			color: var(--color-text-button-disabled);
			font-weight: normal;
		}
	}

	.step:last-child {
		&::after {
			display: none;
		}
	}

	&--horizontal {
		flex-direction: row;
		width: max-content;

		.step {
			.step-horizontal-view;
			&-description,
			&-expand-button {
				display: none;
			}
		}
	}

	&--vertical,
	&--responsive {
		.step:not(:last-child) {
			margin-bottom: @row-gap;
		}

		.step-content {
			margin-bottom: 24px;
		}
	}

	&--responsive {
		flex-direction: column;

		@media @mediaQueryLapAndUp {
			flex-direction: row;
			width: max-content;
			.step {
				.step-horizontal-view;
				&-description,
				&-expand-button {
					display: none;
				}
			}
		}
	}
}
