@import "../../../less/_variables/color-palette.less";
@import "../../../less/_mixins/general.less";

.card {
	min-width: 250px;
	min-height: 300px;
	width: 250px;
	border: 1px solid rgb(214, 214, 214);
	border-radius: 4px;
	padding: 0 16px;
}

.card > a,
.card > a:hover,
.card > a:visited {
	text-decoration: none;
}

.card-body {
	display: block;
	height: 280px;
	color: var(--color-text-default);
}

.card-footer {
	display: block;
	height: 72px;
	color: var(--color-text-default);
}

.card-header {
	display: block;
	height: 72px;
}

.card-divider {
	background-color: rgb(214, 214, 214);
	border: none;
	height: 1px;
	&-bold {
		height: 2px;
	}
}
